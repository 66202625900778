<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-14
 * @Description: 营销管理/平台营销工具/平台卡券活动/新增或者编辑(充电)
-->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
    <ykc-form-item label="活动名称" prop="activityName">
      <ykc-input
        v-model="ruleForm.activityName"
        placeholder="请输入活动名称"
        maxlength="50"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="对外名称" prop="outName">
      <ykc-input v-model="ruleForm.outName" maxlength="50" placeholder="请输入对外名称"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="活动时间" prop="activityTime">
      <ykc-date-picker limitDate="limitBefore" v-model="ruleForm.activityTime"></ykc-date-picker>
    </ykc-form-item>
    <ykc-form-item label="活动说明" prop="activityRemark">
      <ykc-input
        v-model="ruleForm.activityRemark"
        type="textarea"
        placeholder="请输入活动说明"
        maxlength="500"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="卡券设置" class="required">
      <charge-card-count v-model="couponData" :totalCardData="coupons" ref="chargeCard" />
    </ykc-form-item>
    <div class="limited-wrap">
      <ykc-form-item label="限制次数" prop="limitCount">
        <ykc-input
          v-model="ruleForm.limitCount"
          :maxlength="8"
          placeholder="请输入次数"></ykc-input>
      </ykc-form-item>
      <ykc-dropdown
        placeholder="请选择"
        :clearable="false"
        :data="LIMITED_TYPE"
        v-model="ruleForm.limitType"></ykc-dropdown>
    </div>
    <ykc-form-item label="电站维度" prop="stationScope">
      <ykc-radio
        :data="dicStationScopes"
        v-model="ruleForm.stationScope"
        @change="changeAvailable"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item prop="stationCityList" label="选择电站" v-if="ruleForm.stationScope == '4'">
      <ykc-tree
        ref="stationCityTree"
        :data="stationCityData"
        :props="stationProps"
        @check-change="handleStationCityTree"></ykc-tree>
    </ykc-form-item>
    <ykc-form-item prop="stationList" label="选择电站" v-if="ruleForm.stationScope == '1'">
      <ykc-tree
        ref="stationTree"
        :data="stationData"
        :props="stationProps"
        @check-change="handleStationTree"></ykc-tree>
    </ykc-form-item>
    <ykc-form-item prop="stationGroupList" label="选择电站" v-if="ruleForm.stationScope == '2'">
      <ykc-tree
        ref="stationGroupTree"
        :data="stationGroupData"
        :props="stationProps"
        @check-change="handleStationGroupTree"></ykc-tree>
    </ykc-form-item>

    <ykc-form-item label="用户维度" prop="userScope">
      <ykc-radio
        :data="dicUserScopes"
        v-model="ruleForm.userScope"
        @change="changeAvailableUser"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item prop="userList" label="发放用户" v-if="ruleForm.userScope === '1'">
      <ykc-tree
        ref="treeUser"
        :data="usersData"
        :props="userProps"
        @check-change="handleUsersTree" />
    </ykc-form-item>
    <ykc-form-item prop="userGroupList" label="发放用户" v-if="ruleForm.userScope === '2'">
      <ykc-tree
        ref="treeUserGroup"
        :data="userGroupData"
        :props="userProps"
        @check-change="handleUsersGroupTree" />
    </ykc-form-item>
  </ykc-form>
</template>
<script>
  import ChargeCardCount from '@/components/dynamic-compose/charge-card-count';
  import { couponActivity, marketManage, treeUtils } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  export default {
    components: { ChargeCardCount },
    props: {
      editModel: {
        type: String,
        default: 'add', // add edit copy
      },
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        LIMITED_TYPE: [
          {
            id: '1',
            name: '次/人/天',
          },
          {
            id: '2',
            name: '次/人/活动周期',
          },
        ],
        couponData: [
          {
            charge: '',
            cardCount: [
              {
                card: '',
                count: '',
              },
            ],
          },
        ], // 卡券数据
        setData: [],
        coupons: [],
        selectData: [],
        basicData: [
          {
            comName: 'YkcDropdown',
            key: 'couponId',
            remote: true,
            value: '',
            placeholder: '请选择优惠券/抵用卡',
            rules: { required: true, message: '请选择优惠券/抵用卡', trigger: 'blur' },
            prepend: '卡券',
            only: true,
            data: [],
          },
          {
            comName: 'YkcInput',
            value: '',
            key: 'issueCount',
            maxlength: 8,
            prepend: '数量',
            append: '张/人',
            placeholder: '请输入卡券数量',
            rules: [
              { required: true, message: '请输入卡券数量', trigger: 'blur' },
              {
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                  '卡券数量',
                  99999999
                ),
                trigger: 'blur',
              },
              { validator: this.checkCouponNumber, trigger: 'blur' },
            ],
          },
        ],
        stationData: [],
        stationCityData: [],
        usersData: [],
        userGroupData: [],
        userProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        stationsData: [],
        stationGroupData: [],
        stationProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        ruleForm: {
          activityName: '',
          outName: '',
          activityTime: [],
          activityRemark: '',
          limitType: '1',
          limitCount: '',
          limitedType: '',
          stationScope: '4',
          userScope: '1',
          userList: [],
          userGroupList: [],
          stationList: [],
          stationCityList: [],
          stationGroupList: [],
        },
        rules: {
          activityName: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('活动名称'),
              trigger: 'blur',
            },
            { validator: this.onlyCheckName, trigger: 'blur' },
          ],
          outName: [{ required: true, message: '请输入对外名称', trigger: 'blur' }],
          activityTime: [{ required: true, trigger: 'change', message: '请选择活动时间' }],
          activityRemark: [
            { required: false, trigger: 'blur', message: '请输入活动说明' },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              message: regexpObj.regexp.input.textAreaType.errorTips.error('活动说明'),
              trigger: 'blur',
            },
          ],
          limitCount: [
            { required: true, trigger: 'blur', message: '请输入设置次数' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '设置次数',
                99999999
              ),
              trigger: 'blur',
            },
          ],
          userScope: [{ required: true, trigger: 'change', message: '请选择用户维度' }],
          stationScope: [{ required: true, trigger: 'change', message: '请选择电站维度' }],
          stationList: [{ required: true, message: '请选择电站', trigger: 'change' }],
          stationCityList: [{ required: true, message: '请选择电站', trigger: 'change' }],
          stationGroupList: [{ required: true, message: '请选择电站', trigger: 'change' }],
        },
      };
    },
    computed: {
      /**
       * 过滤字典(用户标签/淘淘充机构/机构标签)
       */
      dicStationScopes() {
        // 电站范围（1:商户维度，2:电站维度，3:全部电站）
        return [
          { id: '4', name: '按城市' },
          { id: '1', name: '按商户' },
          { id: '2', name: '按电站分组' },
          { id: '3', name: '全部电站' },
        ];
      },
      dicUserScopes() {
        // 用户范围(1:按客户发放，2:按用户分组，3:指定用户)
        return [
          { id: '1', name: '按客户发放' },
          { id: '2', name: '按用户分组' },
          { id: '3', name: '全部用户' },
        ];
      },
    },
    created() {
      // this.getItem = localGetItem('dictionary');
      this.getData();
    },
    methods: {
      getData() {
        const requests = [];
        const requestCoupons = marketManage.activityList({
          // cardCouponType://卡券类型（1:抵用卡，2:优惠券）
          useType: '1', // 使用类型（1:普通，2:会员）
          undertakerType: '1', // 承担方类型（1:平台，2:商户）
          // undertakerId://承担商户id
          // batchNumber;//批次号
        });

        const userTreeRequest = treeUtils.operatorUsers({});
        const userTreeGroupRequest = treeUtils.operatorUserGroup({});
        const stationTreeRequest = treeUtils.operatorStations({});
        const stationGroupTreeRequest = treeUtils.operatorStationGroup({});
        const userTreeCityRequest = treeUtils.findCityStationTree({});
        requests.push(requestCoupons);
        requests.push(userTreeRequest);
        requests.push(userTreeGroupRequest);
        requests.push(stationTreeRequest);
        requests.push(stationGroupTreeRequest);
        requests.push(userTreeCityRequest);

        if (this.activityId && (this.editModel === 'edit' || this.editModel === 'copy')) {
          const requestDetail = couponActivity.chargeActivityDetail({
            activityId: this.activityId,
          });
          requests.push(requestDetail);
        }
        Promise.all(requests).then(
          ([
            resCoupon,
            usersData,
            userGroup,
            stationData,
            stationGroup,
            stationCityData,
            resDetail,
          ]) => {
            if (resDetail) {
              this.detail = resDetail || {};
            }
            if (resCoupon) {
              this.coupons = [];
              if (
                this.activityId &&
                (this.detail.activityStatus === '1' || this.detail.activityStatus === '2')
              ) {
                // 只有未开始进行中，进行复制卡券
                this.detail.rewardList.forEach(item => {
                  this.coupons.push({
                    cardCouponId: item.rewardId,
                    id: item.rewardId,
                    name: item.rewardName,
                    cardCouponType: item.rewardType,
                    leftCount: item.rewardCount,
                  });
                });
              }
              const coupons = resCoupon.map(item => {
                // key: 'cardCouponId',
                // name: 'cardCouponName',
                const { cardCouponId, cardCouponName, remainderNum } = item;
                item.id = cardCouponId;
                item.name = cardCouponName;
                item.leftCount = remainderNum;
                return item;
              });
              this.coupons.push(...coupons);
              // 如果是选中状态
              if (this.activityId) {
                this.$nextTick(() => {
                  this.setData = this.formatSetData();
                });
              }
            }
            this.formatUserTreeData(usersData || []);
            this.formatUserGroupTreeData(userGroup || []);
            this.formatStationTreeData(stationData || []);
            this.formatStationCityTreeData(stationCityData || []);
            this.formatStationGroupTreeData(stationGroup || []);
            console.log('format', this.stationData, this.stationCityData, this.stationGroupData);
            if (this.activityId) {
              this.fillInitData();
            }
          }
        );
      },
      formatUserTreeData(data) {
        if (data) {
          this.usersData = [];
          if (data) {
            let id = 1000000;
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, users] of Object.entries(data)) {
              console.log(key, users);
              const user = {
                id: id++,
                name: key,
                subNodes: [],
                disabled: false,
              };
              users.forEach(item => {
                user.subNodes.push({
                  id: item.id,
                  name: item.name,
                });
              });
              this.usersData.push(user);
            }
          }
        }
      },
      formatUserGroupTreeData(data) {
        this.userGroupData = [];
        if (data?.length > 0) {
          data.forEach(item => {
            const userGroup = {
              id: item.id,
              name: item.groupName || '未知',
              disabled: false,
            };
            this.userGroupData.push(userGroup);
          });
        }
      },
      formatStationTreeData(data) {
        this.stationData = [];

        if (data?.length > 0) {
          data.forEach(item => {
            const station = {
              id: `0${item.operatorId}`,
              name: item.operatorName || '未知',
              subNodes: [],
              disabled: false,
            };
            if (item?.stationTreeVOList?.length > 0) {
              item.stationTreeVOList.forEach(item1 => {
                const subNode = { id: item1.stationId, name: item1.stationName, disabled: false };
                station.subNodes.push(subNode);
              });
            }
            this.stationData.push(station);
          });
        }
      },
      formatStationCityTreeData(originData) {
        // 转换省市区为data-picker数据
        function transPCDItem(item) {
          const subNodes = [];
          if (item.subTree) {
            item.subTree.forEach(itm => {
              subNodes.push(transPCDItem(itm));
            });
          }
          const obj = {
            id: item.id,
            name: item.name || '未知',
            subNodes,
            disabled: false,
          };
          return obj;
        }

        function transPCD(data) {
          if (!data || !data.length) {
            return [];
          }
          const result = [];
          data.forEach(item => {
            result.push(transPCDItem(item));
          });
          return result;
        }

        this.stationCityData = transPCD(originData);
      },
      formatStationGroupTreeData(data) {
        this.stationGroupData = [];
        if (data?.length > 0) {
          data.forEach(item => {
            const stationGroup = {
              id: item.id,
              name: item.groupName || '未知',
              disabled: false,
            };
            this.stationGroupData.push(stationGroup);
          });
        }
      },
      fillInitData() {
        if (this.editModel === 'edit') {
          this.ruleForm.activityName = this.detail.activityName;
          this.ruleForm.outName = this.detail.outName;
          this.ruleForm.activityTime = [];
          if (this.detail.activityBeginTime) {
            // 这里有问题 编辑没有返回时间
            this.ruleForm.activityTime.push(this.detail.activityBeginTime);
          }
          if (this.detail.activityEndTime) {
            this.ruleForm.activityTime.push(this.detail.activityEndTime);
          }
        }
        this.ruleForm.activityRemark = this.detail.activityRemark || '';

        this.ruleForm.limitType = this.detail.limitType;
        this.ruleForm.limitCount = this.detail.limitCount;
        // 卡券限制数据
        this.couponData = this.formatSelectedData();
        // 电站维度
        this.ruleForm.stationScope = this.detail.stationScope;
        // 用户维度
        this.ruleForm.userScope = this.detail.userScope;
        if (this.ruleForm.userScope === '1') {
          this.ruleForm.userList = this.fillTreeSelectedIds(this.detail.userScopeList);
          this.$nextTick(() => {
            // create生命周期,节点未渲染this.$refs取不到值
            this.selectTreeNodes(this.$refs.treeUser, this.ruleForm.userList);
          });
        } else if (this.ruleForm.userScope === '2') {
          this.ruleForm.userGroupList = this.fillTreeSelectedIds(this.detail.userScopeList);
          this.$nextTick(() => {
            this.selectTreeNodes(this.$refs.treeUserGroup, this.ruleForm.userGroupList);
          });
        }
        if (this.ruleForm.stationScope === '1') {
          this.ruleForm.stationList = this.fillTreeSelectedIds(this.detail.stationScopeList);
          this.$nextTick(() => {
            this.selectTreeNodes(this.$refs.stationTree, this.ruleForm.stationList);
          });
        } else if (this.ruleForm.stationScope === '2') {
          this.ruleForm.stationGroupList = this.fillTreeSelectedIds(this.detail.stationScopeList);
          this.$nextTick(() => {
            this.selectTreeNodes(this.$refs.stationGroupTree, this.ruleForm.stationGroupList);
          });
        } else if (this.ruleForm.stationScope === '4') {
          this.ruleForm.stationCityList = this.fillTreeSelectedIds(this.detail.stationScopeList);
          this.$nextTick(() => {
            this.selectTreeNodes(this.$refs.stationCityTree, this.ruleForm.stationCityList);
          });
        }
      },
      fillTreeSelectedIds(records) {
        const selectedIds = [];
        records.forEach(item => {
          selectedIds.push(item.dataId);
        });
        return selectedIds;
      },
      formatSelectedData() {
        const selectedCoupons = [];
        const chargeNums = [];
        // 先找到有几个充电费用梯度
        this.detail.rewardList.forEach(item => {
          if (chargeNums.indexOf(item.limitPower) < 0) {
            chargeNums.push(item.limitPower);
          }
        });
        console.log('chargeNums', chargeNums);
        // 便利电费梯度数组,组装数据
        for (let i = 0; i < chargeNums.length; i++) {
          const charge = chargeNums[i];
          const cardCount = [];
          // 找到所有相应充电梯度的数据, 组装cardCount
          this.detail.rewardList.forEach(coupon => {
            if (coupon.limitPower === charge) {
              // 找到该梯度
              cardCount.push({
                card: coupon.rewardId,
                count: coupon.rewardCount,
              });
            }
          });
          // 组装一条数据
          selectedCoupons.push({
            charge,
            cardCount,
          });
        }
        /*
           couponData: [
          {
            charge: '',
            cardCount: [
              {
                card: '',
                count: '',
              },
            ],
          },
        ] */
        console.log('selectedCoupons', selectedCoupons);
        return selectedCoupons;
      },
      formatSetData() {
        const selectedCoupons = [];
        this.detail.rewardList.forEach(item => {
          selectedCoupons.push({
            couponId: item.rewardId,
            issueCount: item.rewardCount,
          });
        });
        return selectedCoupons;
      },
      /**
       * 选择树形控件
       * @param tree
       * @param selectedNodes
       */
      selectTreeNodes(tree, selectedNodes) {
        this.$nextTick(() => {
          tree.setCheckedKeys(selectedNodes, true);
        });
      },

      submitForm() {
        return new Promise(resolve => {
          const couponValidForm = this.$refs.chargeCard.validate();
          const dataValidForm = this.validateAllForm();
          Promise.all([couponValidForm, dataValidForm]).then(([couponResult]) => {
            // 新增/复制/编辑
            if (couponResult) {
              const submitData = this.formData();
              submitData.rewardList = this.formatCouponData();
              couponActivity.chargeActivitySave(submitData).then(res => {
                console.log(res);
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      /**
       * 校验表单
       * @returns {Promise<unknown>}
       */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      formatCouponData() {
        const rewards = [];
        this.couponData.forEach(item => {
          const { cardCount } = item;
          if (cardCount && cardCount.length > 0) {
            cardCount.forEach(item1 => {
              const reward = { limitPower: item.charge };
              reward.rewardId = item1.card;
              reward.rewardCount = item1.count;
              for (let i = 0; i < this.coupons.length; i++) {
                if (this.coupons[i].id === item1.card) {
                  reward.rewardType = this.coupons[i].cardCouponType;
                  reward.rewardName = this.coupons[i].cardCouponName;
                  break;
                }
              }
              rewards.push(reward);
            });
          }
        });
        return rewards;
      },
      formData() {
        const startTime =
          this.ruleForm.activityTime[0].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[0]
            : `${this.ruleForm.activityTime[0]} 00:00:00`;
        const endTime =
          this.ruleForm.activityTime[1].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[1]
            : `${this.ruleForm.activityTime[1]} 00:00:00`;
        const formData = {
          activityType: '4',
          activityInitiator: '1',
          activityInitiatorId: '0',
          activityName: this.ruleForm.activityName,
          outName: this.ruleForm.outName,
          activityBeginTime: startTime,
          activityEndTime: endTime,
          activityRemark: this.ruleForm.activityRemark || '',
          limitType: this.ruleForm.limitType,
          limitCount: this.ruleForm.limitCount,

          userScope: this.ruleForm.userScope,
          stationScope: this.ruleForm.stationScope,
        };
        if (this.ruleForm.userScope === '1' || this.ruleForm.userScope === '2') {
          formData.userScopeList =
            this.ruleForm.userScope === '1'
              ? this.getTreeFormatSelectedDatas(this.$refs.treeUser)
              : this.getTreeFormatSelectedDatas(this.$refs.treeUserGroup);
        }

        if (this.ruleForm.stationScope === '1') {
          formData.stationScopeList = this.getTreeFormatSelectedDatas(this.$refs.stationTree);
        }

        if (this.ruleForm.stationScope === '2') {
          formData.stationScopeList = this.getTreeFormatSelectedDatas(this.$refs.stationGroupTree);
        }

        if (this.ruleForm.stationScope === '4') {
          formData.stationScopeList = this.getTreeFormatSelectedDatas(this.$refs.stationCityTree);
        }

        // 编辑/copy
        if (this.editModel === 'edit') {
          formData.activityId = this.activityId;
        }
        return formData;
      },
      getTreeFormatSelectedDatas(tree) {
        const selectedDatas = [];
        const items = tree.getCheckedNodes(true, false);
        items.forEach(item => {
          selectedDatas.push({
            dataId: item.id,
            dataName: item.name,
            orgType: item.orgType,
          });
        });
        return selectedDatas;
      },

      /**
       * 输入框数量校验
       */
      checkCouponNumber(rule, value, callback) {
        // 输入的所有数据
        const result = this.$refs.combination.dealResult();
        // 当前行index
        const rowIndex = rule.field.split('.')[1];
        // 选择的优惠券id
        const { couponId = '' } = result[rowIndex];

        if (!couponId) {
          return callback();
        }
        const [coupon] = this.coupons.filter(item => item.id === couponId);

        return Number(value) > coupon.leftCount
          ? callback(`超过剩余数量${coupon.leftCount}张，请重新输入`)
          : callback();
      },
      handleUsersTree() {
        this.ruleForm.userList = this.$refs.treeUser.getCheckedKeys(true) || [];
        // console.log('this.ruleForm.stationList', checkedKeys, this.ruleForm.stationList);
      },
      handleUsersGroupTree() {
        this.ruleForm.userGroupList = this.$refs.treeUserGroup.getCheckedKeys(true) || [];
      },
      handleStationTree() {
        //  this.ruleForm.stationList = checkedKeys.length > 0 ? checkedKeys : '';
        this.ruleForm.stationList = this.$refs.stationTree.getCheckedKeys(true) || [];
      },
      handleStationCityTree() {
        //  this.ruleForm.stationList = checkedKeys.length > 0 ? checkedKeys : '';
        this.ruleForm.stationCityList = this.$refs.stationCityTree.getCheckedKeys(true) || [];
      },
      handleStationGroupTree(checkedKeys) {
        // this.ruleForm.stationGroupList = checkedKeys.length > 0 ? checkedKeys : '';
        this.ruleForm.stationGroupList = this.$refs.stationGroupTree.getCheckedKeys(true) || [];
        console.log('checkedKeys', checkedKeys, this.ruleForm.stationGroupList);
      },
      /**
       * 活动名称校验
       */
      onlyCheckName(rule, value, callback) {
        if (value) {
          couponActivity
            .checkNameOnly({
              activityName: value,
              activityType: '4',
              activityId: this.editModel === 'edit' ? this.activityId : '',
            })
            .then(res => {
              if (res?.repeatFlag) {
                // 重复
                // 校验未通过
                this.nameValidError = '活动名称已存在'; // res.resultDesc;
                callback(new Error(this.nameValidError));
                // eslint-disable-next-line no-dupe-else-if
              } else if (res?.repeatFlag === false) {
                // 校验通过
                this.nameValidError = '';
                callback();
              }
            })
            .catch(() => {});
        }
      },
      // 清空选择电站数据
      changeAvailable() {
        // 清楚选择的电站数据
        this.ruleForm.stationList = [];
        this.ruleForm.stationCityList = [];
        this.ruleForm.stationGroupList = [];
      },
      // 清空选择用户数据
      changeAvailableUser() {
        // 清楚选择的电站数据
        this.ruleForm.userList = [];
        this.ruleForm.userGroupList = [];
      },
    },
  };
</script>
<style lang="scss">
  .drawer-main-body {
    .limited-wrap {
      display: flex;

      .ykc-form-item {
        flex: 1;
      }

      .ykc-dropdown {
        width: 150px;
        margin-left: 10px;
        margin-top: 26px;
      }
    }
  }
</style>
