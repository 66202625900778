var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ykc-form',{ref:"ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules}},[_c('ykc-form-item',{attrs:{"label":"活动名称","prop":"activityName"}},[_c('ykc-input',{attrs:{"placeholder":"请输入活动名称","maxlength":"50"},model:{value:(_vm.ruleForm.activityName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "activityName", $$v)},expression:"ruleForm.activityName"}})],1),_c('ykc-form-item',{attrs:{"label":"对外名称","prop":"outName"}},[_c('ykc-input',{attrs:{"maxlength":"50","placeholder":"请输入对外名称"},model:{value:(_vm.ruleForm.outName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "outName", $$v)},expression:"ruleForm.outName"}})],1),_c('ykc-form-item',{attrs:{"label":"活动时间","prop":"activityTime"}},[_c('ykc-date-picker',{attrs:{"limitDate":"limitBefore"},model:{value:(_vm.ruleForm.activityTime),callback:function ($$v) {_vm.$set(_vm.ruleForm, "activityTime", $$v)},expression:"ruleForm.activityTime"}})],1),_c('ykc-form-item',{attrs:{"label":"活动说明","prop":"activityRemark"}},[_c('ykc-input',{attrs:{"type":"textarea","placeholder":"请输入活动说明","maxlength":"500"},model:{value:(_vm.ruleForm.activityRemark),callback:function ($$v) {_vm.$set(_vm.ruleForm, "activityRemark", $$v)},expression:"ruleForm.activityRemark"}})],1),_c('ykc-form-item',{staticClass:"required",attrs:{"label":"卡券设置"}},[_c('ykc-combination-item',{ref:"combination",attrs:{"required":"","basicData":_vm.basicData,"selectData":_vm.coupons,"data":_vm.setData,"maxlength":20,"addText":"添加卡券"}})],1),_c('ykc-form-item',{attrs:{"label":"活动范围","prop":"cityScope"}},[_c('ykc-radio',{attrs:{"data":[
        { id: '4', name: '城市' },
        { id: '3', name: '全国' },
      ]},model:{value:(_vm.ruleForm.cityScope),callback:function ($$v) {_vm.$set(_vm.ruleForm, "cityScope", $$v)},expression:"ruleForm.cityScope"}})],1),(_vm.ruleForm.cityScope == '4')?_c('ykc-form-item',{attrs:{"prop":"cityList","label":"选择城市"}},[_c('ykc-cascader',{attrs:{"options":_vm.addressData,"placeholder":"请选择城市","collapseTags":"","configSet":{
        value: 'id',
        label: 'name',
        children: 'subNodes',
        multiple: true,
      }},model:{value:(_vm.ruleForm.cityList),callback:function ($$v) {_vm.$set(_vm.ruleForm, "cityList", $$v)},expression:"ruleForm.cityList"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }